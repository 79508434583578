.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
}
.loader--inline {
  position: relative;
  height: 50px;
  background: none;
}

.rbc-header {
  font-size: 1.2em;
  font-weight: bold;
}

.rbc-header-gutter {
  width: 165px !important;
  word-wrap: break-word;
}

.rbc-time-view .rbc-row {
  min-height: 0;
}

.rbc-event {
  padding: 0 !important;
}

.rbc-time-header > .rbc-row:first-child {
  display: none;
}

.team-changes.rbc-calendar .rbc-time-header > .rbc-row:first-child {
  display: inherit;
}

.global-calendar .rbc-time-content {
  border-top: 0;
}

.rbc-row-segment .rbc-event-content {
  white-space: normal;
}

.rbc-event-content > * {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: #fff;
  color: #000;
}

.rbc-time-view .rbc-allday-cell {
  min-height: 38px;
}

.userPlanning .rbc-month-row {
  flex-basis: auto;
  min-height: 50px;
  height: auto;
}

.rbc-time-header > .rbc-row:first-child {
  border-bottom-width: 2px;
}
.rbc-time-header > .rbc-row > * + * {
  border-left-width: 2px;
}

.rbc-day-bg + .rbc-day-bg {
  border-left-width: 2px;
}

/* border color */
.rbc-month-view,
.rbc-month-view .rbc-header,
.rbc-month-view .rbc-header + .rbc-header,
.rbc-rtl .rbc-month-view .rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-rtl .rbc-day-bg + .rbc-day-bg,
.rbc-agenda-view table,
.rbc-agenda-view table tbody > tr > td + td,
.rbc-rtl .rbc-agenda-view table tbody > tr > td + td,
.rbc-agenda-view table tbody > tr + tr,
.rbc-agenda-view table thead > tr > th,
.rbc-timeslot-group,
.rbc-time-view,
.rbc-time-header.rbc-overflowing,
.rbc-rtl .rbc-time-header.rbc-overflowing,
.rbc-time-header > .rbc-row > * + *,
.rbc-rtl .rbc-time-header > .rbc-row > * + *,
.rbc-time-header > .rbc-row:first-child,
.rbc-time-content,
.rbc-time-content > * + * > *,
.rbc-rtl .rbc-time-content > * + * > * {
  border-color: #2c3e50;
}

.rbc-today {
  background-color: #ecf0f1;
}

.rbc-past-day:not(.rbc-off-range-bg) {
  background-color: #f5f5f5;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.bg-success .navbar-nav .active > .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}

.Onedrive__button {
  margin: 5px;
}

.VideoCapture--dash {
  display: inline-block;
  position: relative;
}
.VideoCapture--dash::before {
  content: ' ';
  border-bottom: 1px solid red;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  display: inline-block;
}

/*
 * This is to kill white space between DTs/DDs when they
 * are displayed as columns (because of inline-block styling)
 */
.MachineDetail__dl {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  word-spacing: -0.43em;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
/*
 * Reset of the white-space hack
 * The width + left/right padding of DTs/DDs equals 50%
 * (to create two equal columns)
 */
.MachineDetail__dt,
.MachineDetail__dd {
  flex: 1 1;
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  padding: 3px 2% 4px;
  margin: 0;
}
/*
* borders
*/
.MachineDetail__dl,
.MachineDetail__groupTitle {
  border-bottom: 1px solid #999;
}

.TaskInfo {
  font-weight: bold;
  font-size: 0.8em;
}

.planningLegend__badgesContainer {
  display: flex;
  flex-wrap: wrap;
}

.planningLegend__badges {
  margin-right: 2px;
  border: 1px solid #424242;
}

.globalPlanning__calendarWeek {
  width: 200%; /* keep for old browser as it's a "good" approximation for the following line */
  width: calc((100% - 165px) * 2 + 165px);
  min-width: 1000px;
}

.globalPlanning__team {
  overflow-x: auto;
}

.globalPlanning__userName {
  /* "fixed" position */
  left: 2px;
  z-index: 100;
  background: #fff;
  border-right: 1px solid #ddd;

  /* global style */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.ProjectContact__highlight {
  background: #95a5a6;
  color: #fff;
  cursor: pointer;
}

.ProjectContact__item {
  padding: 5px;
  border-bottom: 1px solid #888;
}

.Appointment__ActionList {
  min-width: 100px;
}

.TaskMachineInstallation__Tab a {
  background-color: #99999990;
  color: #fff;
}

.TaskMachineInstallation__Tab--customized a {
  background-color: #18bc9c90;
  color: #18bc9c;
}

.TaskMachineInstallation__Tab.active a {
  background-color: #3498db90 !important;
  color: #000 !important;
}

.TaskMachineInstallation__Tab--missingFile a {
  background-color: #d62c1a90 !important;
  color: #fff !important;
}
.TaskMachineInstallation__Tab--missingFile.active a {
  background-color: #d62c1a !important;
  color: #fff !important;
}

