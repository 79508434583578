.globalPlanning__calendarWeek {
  width: 200%; /* keep for old browser as it's a "good" approximation for the following line */
  width: calc((100% - 165px) * 2 + 165px);
  min-width: 1000px;
}

.globalPlanning__team {
  overflow-x: auto;
}

.globalPlanning__userName {
  /* "fixed" position */
  left: 2px;
  z-index: 100;
  background: #fff;
  border-right: 1px solid #ddd;

  /* global style */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
