.ProjectContact__highlight {
  background: #95a5a6;
  color: #fff;
  cursor: pointer;
}

.ProjectContact__item {
  padding: 5px;
  border-bottom: 1px solid #888;
}
