.VideoCapture--dash {
  display: inline-block;
  position: relative;
}
.VideoCapture--dash::before {
  content: ' ';
  border-bottom: 1px solid red;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  display: inline-block;
}
