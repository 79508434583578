.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
}
.loader--inline {
  position: relative;
  height: 50px;
  background: none;
}

.rbc-header {
  font-size: 1.2em;
  font-weight: bold;
}

.rbc-header-gutter {
  width: 165px !important;
  word-wrap: break-word;
}

.rbc-time-view .rbc-row {
  min-height: 0;
}

.rbc-event {
  padding: 0 !important;
}

.rbc-time-header > .rbc-row:first-child {
  display: none;
}

.team-changes.rbc-calendar .rbc-time-header > .rbc-row:first-child {
  display: inherit;
}

.global-calendar .rbc-time-content {
  border-top: 0;
}

.rbc-row-segment .rbc-event-content {
  white-space: normal;
}

.rbc-event-content > * {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: #fff;
  color: #000;
}

.rbc-time-view .rbc-allday-cell {
  min-height: 38px;
}

.userPlanning .rbc-month-row {
  flex-basis: auto;
  min-height: 50px;
  height: auto;
}

.rbc-time-header > .rbc-row:first-child {
  border-bottom-width: 2px;
}
.rbc-time-header > .rbc-row > * + * {
  border-left-width: 2px;
}

.rbc-day-bg + .rbc-day-bg {
  border-left-width: 2px;
}

/* border color */
.rbc-month-view,
.rbc-month-view .rbc-header,
.rbc-month-view .rbc-header + .rbc-header,
.rbc-rtl .rbc-month-view .rbc-header + .rbc-header,
.rbc-day-bg + .rbc-day-bg,
.rbc-rtl .rbc-day-bg + .rbc-day-bg,
.rbc-agenda-view table,
.rbc-agenda-view table tbody > tr > td + td,
.rbc-rtl .rbc-agenda-view table tbody > tr > td + td,
.rbc-agenda-view table tbody > tr + tr,
.rbc-agenda-view table thead > tr > th,
.rbc-timeslot-group,
.rbc-time-view,
.rbc-time-header.rbc-overflowing,
.rbc-rtl .rbc-time-header.rbc-overflowing,
.rbc-time-header > .rbc-row > * + *,
.rbc-rtl .rbc-time-header > .rbc-row > * + *,
.rbc-time-header > .rbc-row:first-child,
.rbc-time-content,
.rbc-time-content > * + * > *,
.rbc-rtl .rbc-time-content > * + * > * {
  border-color: #2c3e50;
}

.rbc-today {
  background-color: #ecf0f1;
}

.rbc-past-day:not(.rbc-off-range-bg) {
  background-color: #f5f5f5;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.bg-success .navbar-nav .active > .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
}
