.TaskMachineInstallation__Tab a {
  background-color: #99999990;
  color: #fff;
}

.TaskMachineInstallation__Tab--customized a {
  background-color: #18bc9c90;
  color: #18bc9c;
}

.TaskMachineInstallation__Tab.active a {
  background-color: #3498db90 !important;
  color: #000 !important;
}

.TaskMachineInstallation__Tab--missingFile a {
  background-color: #d62c1a90 !important;
  color: #fff !important;
}
.TaskMachineInstallation__Tab--missingFile.active a {
  background-color: #d62c1a !important;
  color: #fff !important;
}
